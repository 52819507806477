import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { GET_DMT_TRANS_HISTORY, POST_DMT_REFUND_TRANSACTION, GET_DMT_TRANSACTION_INQUIRY, POST_PAY_SPRINT_REFUND_OTP_REQUEST, POST_PAY_SPRINT_REFUND_REQUEST } from './../../Utils/appConstants';
import { dmtStatus, dmtDetailStatus, channelStatus, isNumber } from './../../Utils/Common';
import { getRequest, postRequest } from './../../Utils/AppApi';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
// import PropagateLoader from "react-spinners/PropagateLoader";
import DmtInvoice from "../../container/dmtInvoice";
import { DatePicker, Modal, Tooltip, Table, Space, Tag } from 'antd';
import { getUTCToLocalDateTimeFormat, queryDate, toDayDate } from '../../Utils/function';

function DmtHistory(props) {
    const history = useHistory();
    // const csvLink = useRef()
    const limit = 10;
    const dateFormat = 'DD-MM-YYYY';

    const [searchAccountNumber, setSearchAccountNumber] = useState(null);
    const [searchTransId, setSearchTransId] = useState(null);
    const [searchCustomerId, setSearchCustomerId] = useState(null);

    const [isLoading, setLoading] = useState(false);
    const [endDate, setEndDate] = useState(toDayDate());
    const [startDate, setStartDate] = useState(toDayDate());
    const [getData, setData] = useState([]);
    // const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [refundData, setRefundData] = useState(null);
    const [page, setPage] = useState(1);
    const [isLimit, setLimit] = useState(limit);
    const [userId, setUserId] = useState(props.userId);
    const [isOtp, setOtp] = useState(null);
    const [isOtpModal, setOtpModal] = useState(false);

    const [invoiceDataModal, setInvoiceDataModal] = useState(false);
    const [invoiceData, setInvoiceData] = useState(null);

    const columns = [{
        name: 'Date Time',
        cell: row => getUTCToLocalDateTimeFormat(row.createdAt),
        width: "200px",
    }, {
        name: 'Trans ID',
        selector: row => <Tooltip placement="bottom" title={row.client_ref_id}>{row.client_ref_id}</Tooltip>,
        width: "180px",
    }, {
        name: 'Customer ID',
        selector: row => <Tooltip placement="bottom" title={row.customer_id}>{row.customer_id}</Tooltip>,
        width: "130px",
    }, {
        name: 'Bank Name',
        selector: row => (row.baneData && row.baneData.bankName) || (row.baneData1 && row.baneData1.bankName),
        width: "220px",
    }, {
        name: 'IFSC Code',
        selector: row => row.baneData ? row.baneData.ifscCode : (row.baneData1) ? row.baneData1.ifscCode : "",
        width: "150px",
    }, {
        name: 'Account Number',
        selector: row => row.baneData ? row.baneData.accountNumber : (row.baneData1) ? row.baneData1.accountNumber : "",
        width: "170px",
    }, {
        name: 'Bene Name',
        selector: row => row.baneData ? (row.baneData.beneBankName ? row.baneData.beneBankName : row.baneData.beneName) : (row.baneData1) ? (row.baneData1.beneBankName ? row.baneData1.beneBankName : row.baneData1.beneName) : "",
        // selector: row => row.baneData && (row.baneData.isVerified) ? row.baneData.beneBankName : row.baneData.beneName,
        width: "190px",
    }, {
        name: 'Mode',
        selector: row => channelStatus(row.channel),
        width: "100px",
    }, {
        name: 'Amount',
        selector: row => row.amount + ' ' + row.currency,
        width: "100px",
    }, {
        name: 'Status',
        selector: row => dmtStatus(row.txtStatus),
    }, {
        name: 'Action',
        selector: row => <><button type="button" className='btn btn-sm border border-success text-success' onClick={() => handleInvoice(row)} title='Print Invoice'><i class="fa fa-print" aria-hidden="true"></i></button> { /*row.isInsufficientFund && row.txtStatus === 0 && <button type="button" className='btn btn-sm border border-dark text-dark' onClick={() => handleSubmit(row)} title='Check Status'><i className="fas fa-sync"></i> Check Status</button> */}</>,
        width: "250px",
    }, {
        name: 'Message',
        selector: row => <Tooltip placement="bottom" title={row.message && row.message}>{row.message && row.message}</Tooltip>,
        width: "200px",
        headerStyle: (selector, id) => {
            return { textAlign: "center" };   // removed partial line here
        },
    }]

    const handleInvoice = (data) => {
        setInvoiceDataModal(true);
        setInvoiceData(data);
    }

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: isLimit };

    const getDataReports = (data) => {
        setLoading(true);
        if (data) {
            setLimit(data)
            params.limit = data;
        }
        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }

        if (props.userId) {
            params.userId = userId ? userId : props.userId;
        }

        params.searchTransId = searchTransId ? searchTransId : "";
        params.searchAccountNumber = searchAccountNumber ? searchAccountNumber : "";
        params.searchCustomerId = searchCustomerId ? searchCustomerId : "";

        const queryString = objectToQueryString(params);
        getRequest(`${GET_DMT_TRANS_HISTORY}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                // setPage(parseInt(response.data.page));
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(0);
            }
            setLoading(false);
        }).catch(function (error) {
            console.log(error)
            setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    // const calculateTimeDifference = (row) => {
    //     const targetTime = new Date(getUTCToLocalDateTimeFormat(row.createdAt)).getTime(); // "29 Oct 2023, 04:39 PM" in milliseconds
    //     const currentTime = new Date().getTime();

    //     const differenceInMinutes = Math.floor((currentTime - targetTime) / (60 * 1000));

    //     return differenceInMinutes;
    // };

    // const handleSubmit = (data) => {
    //     let time = calculateTimeDifference(data);
    //     // console.log("time ----------------", calculateTimeDifference(data));
    //     // const queryString = objectToQueryString(payload);
    //     if (time >= 0) {
    //         setLoading(true)
    //         const payload = {
    //             transactionId: data.client_ref_id,
    //         }
    //         postRequest(`${POST_DMT_REINITIATE_TRANSACTION}`, payload, history).then((response) => {
    //             if (response.success === true) {
    //                 toast.success(response.message);
    //                 getDataReports();
    //             } else {
    //                 toast.error(response.message);
    //             }
    //             setLoading(false);
    //         }).catch(function (error) {
    //             // console.log(error)
    //             setLoading(false);
    //         })
    //     } else {
    //         toast.error("Please check status after " + (60 - time) + " Minutes");
    //     }
    //     // console.log("accept ----",value)
    // }
    // console.log("accept ----",handleSubmit)
    const transactionInquiry = (data) => {
        // console.log("data -------------", data.parentTransactionId)
        setLoading(true)
        const payload = {
            transactionId: data.transactionId,
        }
        let URL = GET_DMT_TRANSACTION_INQUIRY;
        // if(data.transactionId.includes("SI") || data.apiName === "Instant Pay") {
        //     URL = POST_DMT_INSTANT_PAY_TRANSACTION_INQUIRY;
        // } else if(data.transactionId.includes("SP")) {
        //     URL = POST_DMT_PAY_SPRINT_TRANSACTION_INQUIRY;
        // } else {
            // URL = GET_DMT_TRANSACTION_INQUIRY;
        // }
        postRequest(`${URL}`, payload, history).then((response) => {
            if (response.success === true) {
                toast.success(response.message);
                getDataReports();
            } else {
                toast.error(response.message);
            }
            setLoading(false);
        }).catch(function (error) {
            // console.log(error)
            setLoading(false);
        })
    }

    const refundTransaction = (data) => {
        // console.log("data -------------", data.parentTransactionId)
        setRefundData(data);
        if(data && data.transactionId.includes("SP")) {
            setLoading(true);
            const payload = { transactionId: data.transactionId }
            postRequest(`${POST_PAY_SPRINT_REFUND_OTP_REQUEST}`, payload, history).then((response) => {
                if (response.success === true) {
                    setOtpModal(true);
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
                setLoading(false);
            }).catch(function (error) {
                // console.log(error)
                setLoading(false);
            })
        } else {
            setOtpModal(true);
        }
    }

    const handleRefundTransaction = () => {
        setLoading(true)
        const payload = {
            transactionId: refundData.transactionId,
            otp: isOtp
        }
        let URL;
        if(refundData && refundData.transactionId.includes("SP")) {
            URL = POST_PAY_SPRINT_REFUND_REQUEST;
        } else {
            URL = POST_DMT_REFUND_TRANSACTION;
        }
        postRequest(`${URL}`, payload, history).then((response) => {
            if (response.success === true) {
                toast.success(response.message);
                getDataReports();
            } else {
                toast.error(response.message);
            }
            setLoading(false);
        }).catch(function (error) {
            // console.log(error)
            setLoading(false);
        })
    }

    const handlePageChange = (page, size) => {
        // console.log("handlePageChange -------------------", page)
        setPage(parseInt(page));
    };

    const onChangeStartDate = (date, dateString) => {
        // console.log(date, dateString);
        setStartDate(date)
    };

    const onChangeEndDate = (date, dateString) => {
        // console.log(date, dateString);
        setEndDate(date)
    };

    const handleHideModal = () => {
        setOtpModal(false);
        setInvoiceDataModal(false);
    }

    const handleSearch = () => {
        getDataReports();
    }

    const handleClear = () => {
        setStartDate('')
        setEndDate('')
        setPage(1);
        setSearchAccountNumber('');
        setSearchTransId('');
        setSearchCustomerId('');
    }

    useEffect(() => {
        if (props.userId) {
            setUserId(props.userId)
        } 
        // console.log('1---------------',1)
        getDataReports();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // console.log('page---------------',page)
        if(page > 1) {
            getDataReports();
        }
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps
    
    const ExpandableComponent = (props, key) => <div className="ml-5 w-50"><Table dataSource={props.data.dmttransactiondetails} key={key} columns={[
        {
            title: 'Transaction Id',
            dataIndex: 'transactionId',
            key: 'transactionId',
        }, {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        }, {
            title: 'Bank RRN',
            dataIndex: 'bank_ref_num',
            key: 'bank_ref_num',
        }, {
            title: 'Status',
            // dataIndex: 'txstatus_desc',
            key: 'txstatus_desc',
            render: (record, key) => (
                <><Space key={key} size="middle">{dmtDetailStatus(record.tx_status)}</Space></>
            )
        }, {
            title: "Action",
            key: "action",
            render: (record, key) => (
                <><Space key={key} size="middle">
                    {(record.tx_status === "3" && !record.isRefund) && <Tag color={"green"} onClick={() => refundTransaction(record)} className='pointer' key={0}>
                        REFUND
                    </Tag>}
                    {!record.isRefund && (record.tx_status === "0" || record.tx_status === "1" || record.tx_status === "2" || record.tx_status === "3" || record.tx_status === "5") && <Tag color={"yellow"} onClick={() => transactionInquiry(record)} className='pointer' key={0}>
                        INQUIRY
                    </Tag>}
                </Space></>
            )
        },{
            title: 'Message',
            dataIndex: 'reason',
            key: 'reason',
        }
    ]} />
    </div>

    // if (isLoading) {
    //     return <div className="content text-center" style={{ margin: "250px 0" }}>
    //         <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
    //     </div>
    // }

    return (
        <div className="">
            <div className="content p-0">
                <div className="container-fluid">
                    <div className='card'>
                        <div className='card-body'>
                            <div className="row">
                                <div className='col-md-12 date-search mb-0'>
                                    <div className='row'>
                                        <div className='col-md-4 col-lg-4 d-flex'>
                                            <div className="form-group mr-3">
                                                <label className='date-search-label mb-0 text-blue'>From</label>
                                                <DatePicker className="form-control form-control-border" value={startDate} format={dateFormat} onChange={onChangeStartDate} />
                                            </div>
                                            <div className="form-group">
                                                <label className='date-search-label mb-0 text-red'>To</label>
                                                <DatePicker className="form-control form-control-border" value={endDate} format={dateFormat} onChange={onChangeEndDate} />
                                            </div>
                                        </div>
                                        <div className='col-md-2 col-lg-2'>
                                            <div className="form-group">
                                                <label className='date-search-label mb-0 text-red'></label>
                                                <input type="text" className="form-control form-control-border" placeholder="Trans ID" onChange={(e) => setSearchTransId(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className='col-md-2 col-lg-2'>
                                            <div className="form-group">
                                                <label className='date-search-label mb-0 text-red'></label>
                                                <input type="text" className="form-control form-control-border" placeholder="A/C Number" onChange={(e) => setSearchAccountNumber(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className='col-md-2 col-lg-2'>
                                            <div className="form-group">
                                                <label className='date-search-label mb-0 text-red'></label>
                                                <input type="text" className="form-control form-control-border" placeholder="Customer ID" onChange={(e) => setSearchCustomerId(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className='col-md-1 col-lg-1'>
                                            <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                            <button type="submit" className="btn btn-primary rounded-pill px-3" onClick={() => handleSearch()}>Search</button>
                                        </div>
                                        <div className='col-md-1 col-lg-1'>
                                            <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                            <button type="submit" className="btn btn-default rounded-pill px-4" onClick={() => handleClear()}>Clear</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid pt-2 pb-5">
                                <DataTable
                                    title={""}
                                    columns={columns}
                                    data={getData.docs}
                                    defaultSortFieldID={1}
                                    highlightOnHover
                                    pagination
                                    paginationServer
                                    expandableRows
                                    expandableRowsComponent={ExpandableComponent}
                                    progressPending={isLoading}
                                    // selectableRows
		                            // selectableRowDisabled={rowDisabledCriteria}
                                    // selectableRowsComponent={BootyCheckbox}
                                    // selectableRowsComponentProps={selectProps}
                                    paginationTotalRows={getTotalRecord}
                                    onChangeRowsPerPage={getDataReports}
                                    onChangePage={handlePageChange}
                                />
                            </div>
                        </div>
                    </div>
                    {isOtpModal && <Modal title={"OTP CODE"} width={600} open={isOtpModal} onOk={""} onCancel={handleHideModal} footer={null}>
                        <><div className='row'>
                            <div className='col-md-12 form-group'>
                                <label>OTP Number: </label>
                                <input type={'text'} className="form-group form-control" onChange={(e) => setOtp(e.target.value)} maxLength={6} value={isOtp} onKeyDown={isNumber} />
                            </div>
                            <div className='col-md-12 form-group text-right'>
                                <button type='button' onClick={() => handleHideModal()} className='btn btn-sm btn-default mr-2'>Cancel</button>
                                <button type='button' onClick={() => handleRefundTransaction()} className='btn btn-sm btn-primary'>Send</button>
                            </div>
                        </div></>
                    </Modal>}
                    {invoiceDataModal && <Modal open={invoiceDataModal} width={800} footer={false} onCancel={handleHideModal} closable={false}>
                        <DmtInvoice transactionId={invoiceData.client_ref_id} />
                    </Modal>}
                </div>
            </div>
        </div>
    )
}

export default DmtHistory