import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import PropagateLoader from "react-spinners/PropagateLoader";
import axios from 'axios';
import { API_URL } from './config';
import PrivateRoute from './Utils/PrivateRoute';
import { getToken } from './Utils/Common';
import AppHeader from './components/Header/Header';
import AppFooter from './components/Footer/Footer';
import Dashboard from './components/Dashboard';
import DistributorDashboard from './components/dashboard/DistributorDashboard';
import AccountSettings from './components/AccountSettings';
import mobileRecharge from './components/mobileRecharge';
import uitilityPayment from './components/uitilityPayment';
import uitilityPayment2 from './components/uitilityPayment2';
import addServiceAndOperator from './components/admin/addServiceAndOperator';
import BharatBillpay from './components/bharatBillpay';
import SiteSetting from './components/SiteSetting';
import MyProfile from './components/myProfile';
import transferUser from './components/admin/transferUser';
import fundRequestMaker from './components/admin/fundRequestMaker';
import fundRequestApprover from './components/admin/fundRequestApprover';
import fundRequest from './components/fundRequestPost';
import fundRequestAdminReport from './components/admin/reports/fundRequestReport';
import instantTopup from './components/instantTopup/index';
import instantTopupPaymentResponse from './components/instantTopup/paymentResponse';
import instantTopupPaymentResponseFailed from './components/instantTopup/paymentResponseFailed';
import claimPayment from './components/claimPayment';
import PrepaidReports from './components/reports/prepaidReports/index';
import UitilityReports from './components/reports/uitilityReports';
import ManageUser from './components/admin/manageUser';
import ApprovedUser from './components/admin/approvedUser';
import EKycVerified from './components/admin/eKycVerified';
import WalletRecharge from './components/admin/walletRecharge';
import kycPendingUser from './components/admin/kycPendingUser';
import updateAadhaarKyc from './components/admin/updateAadhaarKyc';
import AdminBankdetail from './components/admin/bankdetail';
import BankSetting from './components/banksetting';
import BankDetailsInfo from './components/bankDetailsInfo';
import RequestMoney from './components/requestMoney';
import CreateUser from './components/distributor/createPartner';
import CreateStaff from './components/staff';
import PartnerLedger from './components/partnerLedger';
import RequestMoneyReport from './components/reports/requestMoneyReports';
import CodMySendReport from './components/codRequest/sendReport';
import PaymentDeposit from './components/paymentDeposit/';
import PaymentDepositReceived from './components/distributor/paymentDepositReceived';
import RequestMoneyReceived from './components/distributor/requestMoneyReceived';
import CodMoneyReceived from './components/codRequest/receivedRequest';
import ApprovedRequestMoney from './components/distributor/approvedRequestMoney';
import CodRequestApproved from './components/codRequest/codRequestApproved';
import MoveToDistributor from './components/moveToDistributor';
import MoveToDistributorReports from './components/reports/moveToDistributorReports';
import moveFromPartnerReport from './components/reports/moveFromPartnerReport';
import ChannelWalletLedger from './components/reports/channelWalletLedger';
import MyLedger from './components/ledger';
import MyFundRequestReport from './components/reports/fundRequestPartnerReports';
import DailyTransactionReport from './components/admin/reports/dailyTransactionReport';
import CommissionReport from './components/admin/reports/commissionReport';
import paymentPayU from './components/paymentPayU/index';
import utilityPaymentMaker from './components/admin/utilityPaymentMaker';
import utilityPaymentApprover from './components/admin/utilityPaymentApprover';
import moneyTransferReport from './components/admin/reports/moneyTransfer';
import moneyTransferFailReport from './components/admin/reports/moneyTransferFail';
import aepsWithdrawalReport from './components/admin/reports/aepsWithdrawal';
import mobileRechargeReport from './components/admin/reports/mobileRecharge';
import dthRechargeReport from './components/admin/reports/dthRecharge';
import upiTransferReport from './components/admin/reports/upiTransfer';
import codRequestModel from './components/codRequest/index';
import packagesModel from './components/admin/packages';
import wallertHistory from './components/wallertHistory';
import createSlab from './components/admin/slab';
import slabList from './components/admin/slab/list';
import MoveToBank from './components/moveToBank/index'
import SettleToWallet from './components/settleToWallet'
import paymentDepositHistory from './components/paymentDeposit/history';
import bankSettingList from './components/admin/bankSettingList';
import upiLoadMoneyReport from './components/upiLoadMoney/report';
import instantAepsComponent from './components/aepsInstant';
// import Aeps2Component from './components/aeps2';
import AepsRegisterDownloadReport from './components/admin/reports/aepsRegisterDownloadReport';
import fingAepsComponent from './components/aepsFingpay';
import PartnerEKYC from './components/partnerEKYC';
import UPIQRCodeMini from './components/upiQrCode/index';
import UPIQRCodeMiniHistory from './components/upiQrCode/history';
import MoneyTransfer from './components/moneyTransfer';
// import MoneyTransferV2 from './components/moneyTransferV2';
import RegisterMatm from './components/serviceMATM/registerMatm';
import ServiceMATM from './components/serviceMATM/index';
import MoneyTransferHistory from './components/reports/moneyTransfer';
import CreateServices from './components/admin/service/createServices';
import UpiTranferReport from './components/reports/upiTransferReports/index';
import AepsHistoryReport from './components/reports/aepsHistory';
import Commission from './components/commission/index';
import FindBankDetailFromShortCode from './components/findBankDetailFromShortCode';
import Complaint from './components/complaint';
import AdminComplaintList from './components/complaint/adminComplaintList';
import HomeDistributor from './components/home/distributor';
import DmtRefundedHistory from './components/admin/reports/dmtRefundedHistory';
import DmtDetailsHistory from './components/admin/reports/dmtDetailsHistory';
import RefundPendingReport from './components/admin/reports/refundPendingReport';
import ShopRegistrationList from './components/admin/reports/shopRegistrationList';
import aepsThreewayReport from './components/admin/reports/aepsThreewayReport';
import matmThreewayReport from './components/admin/reports/matmThreewayReport';
import instantTopupHistory from './components/admin/reports/instantTopupHistory';
import DistributorSalesDashboard from './components/dashboard/DistributorSalesDashboard';
import EPayoutHistory from './components/payout/history';
import SettlementTOBank from './components/admin/reports/settlementToBank';
import RemittanceTransferAdmin from './components/admin/reports/remittanceTransfer';
import RemittanceTransferFailAdmin from './components/admin/reports/remittanceTransferFail';
import CommissionRelease from './components/commissionRelease/index';
import TransactionType from './components/transactionType/index';
import appSetting from './components/admin/setting/index';
import instantTopupReport from './components/instantTopup/report';
import dmtDistributorReport from './components/reports/dmtDistributorReport';
import qrcodeMiniReport from './components/admin/reports/qrcodeMiniReport';
import qrcodeMiniHistory from './components/admin/reports/qrcodeMiniHistory';
import aepsLoginReport from './components/admin/reports/aepsLoginReport';
import Maintenance from './components/Maintenance';
import ipayMerchants from './components/admin/ipayMerchants';
import MatmRegistration from './components/admin/reports/matmRegistrationList';
import MatmTransactionReport from './components/admin/reports/matmTransactionReport';
import InstantPayStatementReport from './components/admin/reports/instantPayStatement';
import MatmReport from './components/reports/matmReport';
import MatmPurchaseList from './components/vender/purchaselist';
import Advertisement from './components/advertisement';
import MonthlyDmtCommission from './components/monthlyDmtCommission/index';
import PayoutService from './components/payoutService';
import { APP_INFO } from './Utils/appConstants';
import { reactLocalStorage } from 'reactjs-localstorage';

require('dotenv').config();
function App() {
  const [authLoading, setAuthLoading] = useState(true);
  const [isMaintenance, setIsMaintenance] = useState(false);
  useEffect(() => {
    // console.log("App ----------------------------------");
    const token = getToken();
    if (!token) {
      return;
    }
    checkUnderMaintenance();
    setAuthLoading(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const checkUnderMaintenance = () => {
    axios.get(API_URL + `/appinfo`).then(response => {
      setAuthLoading(true);
      if (response) {
        reactLocalStorage.set(APP_INFO, JSON.stringify(response.data), true);
        setIsMaintenance(response.data.isMaintenance);
      }
      setAuthLoading(false);
    }).catch(error => {
      setAuthLoading(false);
    });
  }

  if (authLoading && getToken()) {
    return <div className="content text-center" style={{ margin: "250px 0" }}>
      <PropagateLoader color="#5851D2" loading={authLoading} size={25} />
    </div>
  }
  if (isMaintenance) {
    return (<><AppHeader /><Maintenance message="We are currently under maintenance. Please check back later." /></>)
  }

  return (
    <div className="App">
      <Router>
        <AppHeader />
        <Switch>
          <div className="wrapper">
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute exact path="/distributor-dashboard" component={DistributorDashboard} />
            <PrivateRoute exact path="/myprofile" component={MyProfile} />
            <PrivateRoute exact path="/account-settings" component={AccountSettings} />
            <PrivateRoute exact path="/mobile-recharge" component={mobileRecharge} />
            <PrivateRoute exact path="/bharat-billpay-bbps" component={BharatBillpay} />
            <PrivateRoute exact path="/uitility-payment" component={uitilityPayment} />
            <PrivateRoute exact path="/uitility-payment-2-0" component={uitilityPayment2} />
            <PrivateRoute exact path="/add-service-and-operator" component={addServiceAndOperator} />
            <PrivateRoute exact path="/claim-payment" component={claimPayment} />
            <PrivateRoute exact path="/aeps-login" component={aepsLoginReport} />
            <PrivateRoute exact path="/fund-request" component={fundRequest} />
            <PrivateRoute exact path="/fund-request-maker" component={fundRequestMaker} />
            <PrivateRoute exact path="/fund-request-approver" component={fundRequestApprover} />
            <PrivateRoute exact path="/fund-request-reports" component={fundRequestAdminReport} />
            <PrivateRoute exact path="/instant-topup" component={instantTopup} />
            <PrivateRoute exact path="/instant-topup/paymentResponse" component={instantTopupPaymentResponse} />
            <PrivateRoute exact path="/instant-topup/paymentResponseFailed" component={instantTopupPaymentResponseFailed} />
            <PrivateRoute exact path="/setting" component={SiteSetting} />
            <PrivateRoute exact path="/prepaid-reports" component={PrepaidReports} />
            <PrivateRoute exact path="/uitility-reports" component={UitilityReports} />
            <PrivateRoute exact path="/manage-users" component={ManageUser} />
            <PrivateRoute exact path="/approved-users" component={ApprovedUser} />
            <PrivateRoute exact path="/e-kyc-verified" component={EKycVerified} />
            <PrivateRoute exact path="/kyc-pending-users" component={kycPendingUser} />
            <PrivateRoute exact path="/kyc-aadhaar-pending-users" component={updateAadhaarKyc} />
            <PrivateRoute exact path="/admin-bank-detail" component={AdminBankdetail} />
            <PrivateRoute exact path="/bank-setting" component={BankSetting} />
            <PrivateRoute exact path="/bank-info-detail" component={BankDetailsInfo} />
            <PrivateRoute exact path="/request-money" component={RequestMoney} />
            <PrivateRoute exact path="/create-users" component={CreateUser} />
            <PrivateRoute exact path="/create-employee" component={CreateStaff} />
            <PrivateRoute exact path="/request-money-reports" component={RequestMoneyReport} />
            <PrivateRoute exact path="/cod-request-reports" component={CodMySendReport} />
            <PrivateRoute exact path="/upi-transfer-reports" component={UpiTranferReport} />
            <PrivateRoute exact path="/payment-deposit" component={PaymentDeposit} />
            <PrivateRoute exact path="/partner-ledger" component={PartnerLedger} />
            <PrivateRoute exact path="/payment-deposit-list" component={PaymentDepositReceived} />
            <PrivateRoute exact path="/request-money-list" component={RequestMoneyReceived} />
            <PrivateRoute exact path="/cod-request-list" component={CodMoneyReceived} />
            <PrivateRoute exact path="/request-approved-reports" component={ApprovedRequestMoney} />
            <PrivateRoute exact path="/cod-request-approved-reports" component={CodRequestApproved} />
            <PrivateRoute exact path="/move-to-distributor" component={MoveToDistributor} />
            <PrivateRoute exact path="/move-to-distributor-reports" component={MoveToDistributorReports} />
            <PrivateRoute exact path="/move-to-partner-reports" component={moveFromPartnerReport} />
            <PrivateRoute exact path="/move-to-bank" component={MoveToBank} />
            <PrivateRoute exact path="/settlement-to-bank" component={SettlementTOBank} />
            <PrivateRoute exact path="/channel-wallet-ledger" component={ChannelWalletLedger} />
            <PrivateRoute exact path="/myledger" component={MyLedger} />
            <PrivateRoute exact path="/admin-wallet-recharge" component={WalletRecharge} />
            <PrivateRoute exact path="/myfund-request-report" component={MyFundRequestReport} />
            <PrivateRoute exact path="/utility-payment-offline-maker" component={utilityPaymentMaker} />
            <PrivateRoute exact path="/utility-payment-offline-approver" component={utilityPaymentApprover} />
            <PrivateRoute exact path="/cod-request" component={codRequestModel} />
            <PrivateRoute exact path="/wallet-history" component={wallertHistory} />
            <PrivateRoute exact path="/packages" component={packagesModel} />
            <PrivateRoute exact path="/paymentPayU" component={paymentPayU} />
            <PrivateRoute exact path="/create-slab" component={createSlab} />
            <PrivateRoute exact path="/slab-list" component={slabList} />
            <PrivateRoute exact path="/payment-deposit-history" component={paymentDepositHistory} />
            <PrivateRoute exact path="/settle-to-wallet" component={SettleToWallet} />
            <PrivateRoute exact path="/agent-bank-list" component={bankSettingList} />
            <PrivateRoute exact path="/appSetting" component={appSetting} />
            <PrivateRoute exact path="/aeps" component={fingAepsComponent} />
            <PrivateRoute exact path="/express-aeps" component={instantAepsComponent} />
            {/* <PrivateRoute exact path="/express-aeps" component={Aeps2Component} /> */}
            <PrivateRoute exact path="/partner-ekyc" component={PartnerEKYC} />
            <PrivateRoute exact path="/upiqrmini-code" component={UPIQRCodeMini} />
            <PrivateRoute exact path="/upiqrmini-history" component={UPIQRCodeMiniHistory} />
            <PrivateRoute exact path="/payout-service" component={PayoutService} />
            
            <PrivateRoute exact path="/transfer-user" component={transferUser} />
            <PrivateRoute exact path="/money-transfer" component={MoneyTransfer} />
            {/* <PrivateRoute exact path="/money-transfer-v2" component={MoneyTransferV2} /> */}
            <PrivateRoute exact path="/money-transfer-history" component={MoneyTransferHistory} />
            <PrivateRoute exact path="/create-services" component={CreateServices} />
            <PrivateRoute exact path="/commission-list" component={Commission} />
            <PrivateRoute exact path="/money-transfer-report" component={moneyTransferReport} />
            <PrivateRoute exact path="/money-transfer-fail-report" component={moneyTransferFailReport} />
            <PrivateRoute exact path="/refund-pending-report" component={RefundPendingReport} />
            <PrivateRoute exact path="/dmt-refunded-history" component={DmtRefundedHistory} />
            <PrivateRoute exact path="/dmt-detail-history" component={DmtDetailsHistory} />
            <PrivateRoute exact path="/aeps-withdrawal-report" component={aepsWithdrawalReport} />
            <PrivateRoute exact path="/mobile-recharge-report" component={mobileRechargeReport} />
            <PrivateRoute exact path="/dth-recharge-report" component={dthRechargeReport} />
            <PrivateRoute exact path="/upi-transfer-report" component={upiTransferReport} />
            <PrivateRoute exact path="/aeps-history-report" component={AepsHistoryReport} />
            <PrivateRoute exact path="/aeps-threeway-report" component={aepsThreewayReport} />
            <PrivateRoute exact path="/matm-threeway-report" component={matmThreewayReport} />
            <PrivateRoute exact path="/instant-topup-history" component={instantTopupHistory} />
            <PrivateRoute exact path="/aeps-register-download-report" component={AepsRegisterDownloadReport} />
            <PrivateRoute exact path="/find-bank-detail" component={FindBankDetailFromShortCode} />
            <PrivateRoute exact path="/shop-registration-list" component={ShopRegistrationList} />
            <PrivateRoute exact path="/complaint" component={Complaint} />
            <PrivateRoute exact path="/complaint-history" component={AdminComplaintList} />
            <PrivateRoute exact path="/home" component={HomeDistributor} />
            <PrivateRoute exact path="/remittance-transfer-report" component={EPayoutHistory} />
            <PrivateRoute exact path="/remittance-admin-report" component={RemittanceTransferAdmin} />
            <PrivateRoute exact path="/remittance-failed-report" component={RemittanceTransferFailAdmin} />
            <PrivateRoute exact path="/distributor-sales-dashboard" component={DistributorSalesDashboard} />
            <PrivateRoute exact path="/commission-release" component={CommissionRelease} />
            <PrivateRoute exact path="/transaction-type-report" component={TransactionType} />
            <PrivateRoute exact path="/instant-topup-report" component={instantTopupReport} />
            <PrivateRoute exact path="/dmt-distributor-report" component={dmtDistributorReport} />
            <PrivateRoute exact path="/upiload-money-report" component={upiLoadMoneyReport} />
            <PrivateRoute exact path="/ipay-merchants" component={ipayMerchants} />
            <PrivateRoute exact path="/qrcode-mini-report" component={qrcodeMiniReport} />
            <PrivateRoute exact path="/qrcode-mini-history" component={qrcodeMiniHistory} />
            <PrivateRoute exact path="/daily-transaction-report" component={DailyTransactionReport} />
            <PrivateRoute exact path="/commission-report" component={CommissionReport} />
            <PrivateRoute exact path="/product-request" component={ServiceMATM} />
            <PrivateRoute exact path="/matm-registration-list" component={MatmRegistration} />
            <PrivateRoute exact path="/matm-registration" component={RegisterMatm} />
            <PrivateRoute exact path="/matm-transaction-report" component={MatmTransactionReport} />
            <PrivateRoute exact path="/matm-report" component={MatmReport} />
            <PrivateRoute exact path="/matm-order-list" component={MatmPurchaseList} />
            <PrivateRoute exact path="/ipay-statement" component={InstantPayStatementReport} />
            <PrivateRoute exact path="/advertisement" component={Advertisement} />
            <PrivateRoute exact path="/monthly-dmt-commission" component={MonthlyDmtCommission} />
          </div>
        </Switch>
        <AppFooter />
      </Router>
    </div>
  );
}

export default App;