import React, { useEffect, useState } from 'react';
// import { APP_KEY, API_URL } from './../config';
// import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
// import { Tabs, Tab } from 'react-bootstrap-tabs';
import { GET_DMT_TRANS_HISTORY, GET_DMT_TRANSACTION_INQUIRY, GET_MY_CHILD_USERS } from './../../Utils/appConstants';
import { getRequest, postRequest } from './../../Utils/AppApi';
import PropagateLoader from "react-spinners/PropagateLoader";
import { useHistory } from "react-router-dom";
import { /* ToastContainer,*/ toast } from 'react-toastify';
import { channelStatus, getUser, dmtStatus, dmtDetailStatus } from './../../Utils/Common';
// import AppFooter from './../Footer/Footer';
// import Moment from 'react-moment';
// import moment from 'moment';

import { DatePicker, Select, Tooltip,  Table, Space, Tag } from 'antd';
import { getUTCToLocalDateTimeFormat } from '../../Utils/function';

const user = getUser();

function DmtDistributorReport(props) {

    const history = useHistory();
    // const csvLink = useRef()
    const limit = 10;

    const dateFormat = 'DD-MM-YYYY';
    const [isLoading, setLoading] = useState(false);
    const [endDate, setEndDate] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [getData, setData] = useState([]);
    // const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [page, setPage] = useState(1);
    const [myUsers, setMyUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null)

    const columns = [{
        name: 'Date Time',
        cell: row => getUTCToLocalDateTimeFormat(row.createdAt),
        width: "180px",
    }, {
        name: 'Trans ID',
        selector: row => <Tooltip placement="bottom" title={row.client_ref_id && row.client_ref_id}>{row.client_ref_id && row.client_ref_id}</Tooltip>,
        width: "180px",
    }, {
        name: 'Customer ID',
        selector: row => <Tooltip placement="bottom" title={row.customer_id}>{row.customer_id}</Tooltip>,
        width: "130px",
    }, {
        name: 'Bank Name',
        selector: row => (row.baneData && row.baneData.bankName) || (row.baneData1 && row.baneData1.bankName),
        width: "220px",
    }, {
        name: 'IFSC Code',
        selector: row => row.baneData ? row.baneData.ifscCode : (row.baneData1) ? row.baneData1.ifscCode : "",
        width: "140px",
    }, {
        name: 'Account Number',
        selector: row => row.accountNumber,
        width: "170px",
    }, {
        name: 'Bene Name',
        selector: row => row.baneData ? row.baneData.beneName : (row.baneData1) ? row.baneData1.beneName : "",
        width: "190px",
    }, {
        name: 'Mode',
        selector: row => channelStatus(row.channel),
        width: "100px",
    }, {
        name: 'Amount',
        selector: row => row.amount + ' ' + row.currency,
        width: "100px",
    }, {
        name: 'Status',
        selector: row => dmtStatus(row.txtStatus),
    }]

    const getMyUser = () => {
        getRequest(GET_MY_CHILD_USERS, history).then((response) => {
            if (response.success === true) {
                setMyUsers(response.data.map((item) => {
                    let name = item.username + ' - ' + item.name + ' - ' + item.rolecode;
                    return { "value": item._id, "label": name };
                }));
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    }

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: limit };

    const getDataReports = () => {
        if (startDate && endDate) {
            params.startDate = startDate ? startDate : "";
            params.endDate = endDate ? endDate : "";
        }

        if (selectedUser) {
            params.userId = selectedUser;
        }

        const queryString = objectToQueryString(params);
        getRequest(`${GET_DMT_TRANS_HISTORY}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    // const getCSVDataList = () => {
    //     let params = {}
    //     params.exportsCsv = true;
    //     if (startDate && endDate) {
    //         params.startDate = startDate ? startDate : "";
    //         params.endDate = endDate ? endDate : "";
    //     }

    //     if (user.data.role.code !== "P") {
    //         params.userId = selectedUser;
    //     }

    //     const queryString = objectToQueryString(params);
    //     getRequest(`${GET_DMT_TRANS_HISTORY}?${queryString}`, history).then((response) => {
    //         if (response.success === true) {
    //             let csvdataRecord = [];
    //             let csvdata = response.data;
    //             csvdata.map((e) => {
    //                 return csvdataRecord.push({ "Date": moment(e.updatedAt).format("YYYY-MM-DD HH:mm A"), "TXT Id": e.transactionId, "FTR Reference Number": e.FTRReferenceNumber, "Name": e.fromUserDetails.name, "User ID": e.fromUserDetails.username, "Debit": e.debit, "Credit": e.credit, "Closing Balance": e.closingBalance, "Payment Mode": e.paymentMode, "Remark": e.remark });
    //             });
    //             setCsvData(csvdataRecord);
    //             setTimeout(() => {
                //     csvLink.current.link.click();
                // }, 1000);
    //         } else {
    //             setCsvData([]);
    //         }
    //     }).catch(function (error) {
    //         console.log(error)
    //         // setLoading(false);
    //     })
    // }

    const handlePageChange = page => {
        setPage(page);
    };

    const onChangeStartDate = (date, dateString) => {
        // console.log(date, dateString);
        setStartDate(dateString)
    };

    const onChangeEndDate = (date, dateString) => {
        // console.log(date, dateString);
        setEndDate(dateString)
    };

    const onChangeValueSelect = (event) => {
        // const { value } = event.target;
        console.log("-----------", event)
        setSelectedUser(event);
    }

    const handleSearch = () => {
        getDataReports();
    }

    const transactionInquiry = (data) => {
        // console.log("data -------------", data.parentTransactionId)
        setLoading(true)
        const payload = {
            transactionId: data.transactionId,
        }
        let URL = GET_DMT_TRANSACTION_INQUIRY;
        // if(data.transactionId.includes("SI") || data.apiName === "Instant Pay") {
        //     URL = POST_DMT_INSTANT_PAY_TRANSACTION_INQUIRY;
        // } else if(data.transactionId.includes("SP")) {
        //     URL = POST_DMT_PAY_SPRINT_TRANSACTION_INQUIRY;
        // } else {
            // URL = GET_DMT_TRANSACTION_INQUIRY;
        // }
        postRequest(`${URL}`, payload, history).then((response) => {
            if (response.success === true) {
                toast.success(response.message);
                getDataReports();
            } else {
                toast.error(response.message);
            }
            setLoading(false);
        }).catch(function (error) {
            // console.log(error)
            setLoading(false);
        })
    }

    useEffect(() => {
        if (user.data.role.code !== "P") {
            getMyUser()
        }
    }, [])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedUser) {
            getDataReports();
        } else {
            getDataReports();
        }
        // if(user.data.role.code === "P") {
        // getDataReports();
        // }
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

    const ExpandableComponent = (props, key) => <div className="ml-5 w-50"><Table dataSource={props.data.dmttransactiondetails} key={key} columns={[
        {
            title: 'Transaction Id',
            dataIndex: 'transactionId',
            key: 'transactionId',
        }, {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        }, {
            title: 'Bank RRN',
            dataIndex: 'bank_ref_num',
            key: 'bank_ref_num',
        }, {
            title: 'Status',
            // dataIndex: 'txstatus_desc',
            key: 'txstatus_desc',
            render: (record, key) => (
                <><Space key={key} size="middle">{dmtDetailStatus(record.tx_status)}</Space></>
            )
        }, {
            title: "Action",
            key: "action",
            render: (record, key) => (
                <><Space key={key} size="middle">
                    {/* {(record.tx_status === "3" && !record.isRefund) && <Tag color={"green"} onClick={() => refundTransaction(record)} className='pointer' key={0}>
                        REFUND
                    </Tag>} */}
                    {!record.isRefund && (record.tx_status === "1" || record.tx_status === "2" || record.tx_status === "3" || record.tx_status === "5") && <Tag color={"yellow"} onClick={() => transactionInquiry(record)} className='pointer' key={0}>
                        INQUIRY
                    </Tag>}
                </Space></>
            )
        }
    ]} />
    </div>

if (isLoading) {
    return <div className="content text-center" style={{ margin: "250px 0" }}>
        <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
    </div>
}

    return (
        <div>
            <div className="content-wrapper pt-2">
                <div className="content-header my-0 py-0">
                    <div className="container-fluid">
                        <div className="row mb-0 align-items-center">
                            <div className="col-sm-6">
                                <h1 className='page-title text-blue font-merriweather'>Partner DMT <span className='text-red'>Report</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className='card col-md-12'>
                            <div className='card-body'>
                                <div className="row">
                                    <div className='col-md-8 date-search mb-0'>
                                        <div className='row'>
                                            {(user.data.role.code !== "P") && <div className='col-sm-6 col-lg-4'>
                                                <div className="form-group">
                                                    <label className='date-search-label mb-0 text-blue'>Select User</label>
                                                    <Select options={myUsers} className={`normal-light w-100`} showSearch filterOption={(input, option) =>
                                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                    } classNamePrefix="select" placeholder="Select " onChange={(e) => onChangeValueSelect(e)} />
                                                </div>
                                            </div>}
                                            <div className='col-sm-3 col-lg-2'>
                                                <div className="form-group">
                                                    <label className='date-search-label mb-0 text-blue'>From</label>
                                                    <DatePicker className="form-control form-control-border" format={dateFormat} onChange={onChangeStartDate} />
                                                </div>
                                            </div>
                                            <div className='col-sm-3 col-lg-2'>
                                                <div className="form-group">
                                                    <label className='date-search-label mb-0 text-red'>To</label>
                                                    {/* <input type="text" className="form-control form-control-border" placeholder="27 September 2022" /> */}
                                                    <DatePicker className="form-control form-control-border" format={dateFormat} onChange={onChangeEndDate} />
                                                </div>
                                            </div>
                                            <div className='col-sm-4 col-lg-2'>
                                                <div><label className='date-search-label mb-0 text-red'>&nbsp;</label></div>
                                                <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSearch()}>Search</button>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className='col-md-3'>
                                        <div className='float-sm-right'>
                                            <button type="button" className="btn btn-primary mr-3" onClick={() => getCSVDataList()} ><i className="fa fa-file-excel"></i> Export</button>
                                            <CSVLink
                                                data={getCsvData}
                                                filename='move-to-distributor-reports'
                                                className='hidden'
                                                ref={csvLink}
                                                target='_blank'
                                            />
                                            <button type="button" className="btn btn-danger"><i className="fa fa-file-pdf"></i> Export to PDF</button>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="container-fluid pt-2 pb-5">
                                    <DataTable
                                        title={""}
                                        columns={columns}
                                        data={getData.docs}
                                        defaultSortFieldID={1}
                                        highlightOnHover
                                        pagination
                                        paginationServer
                                        expandableRows
                                        expandableRowsComponent={ExpandableComponent}
                                        paginationTotalRows={getTotalRecord}
                                        onChangeRowsPerPage={getDataReports}
                                        onChangePage={handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DmtDistributorReport