import React, { useEffect, useState } from "react";
import './../webcss/invoice.css';
import { POST_PAYOUT_TRANSACTION_INVOICE } from './../Utils/appConstants';
import { getUTCToLocalDateTimeFormat } from './../Utils/function';
// import { dmtStatus, dmtDetailStatus } from './../Utils/Common';
import { postRequest } from './../Utils/AppApi';
import { useHistory } from "react-router-dom";

function Invoice(props) {
    const history = useHistory();
    const [idata, setData] = useState({});
    const [isPrintButton, setPrintButton] = useState(true);
    // const [isDetail, setDetail] = useState([]);
    const print = () => {
        setPrintButton(false)
        setTimeout(() => {
            window.print()
        }, 100);
    }
    const handleInvoice = (transactionId) => {
        let payload = { transactionId : transactionId }
        postRequest(`${POST_PAYOUT_TRANSACTION_INVOICE}`, payload, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                // setDetail(response.data.dmttransactiondetails);
            }
        }).catch(function (error) {
            // console.log(error)
            setData({});
            // setDetail([]);
        })
        // console.log("accept ----",value)
    }

    useEffect(() => {
        // console.log("props.transactionId ----",props.transactionId)
        handleInvoice(props.transactionId);
    }, [props.transactionId]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="container-fluid border border-default p-3">
            <div className="row">
                <div className="col-12 col-lg-12">
                    {/* <div className="row">
                            <div className="col-12">
                                <div className="text-center text-150">
                                    <i className="fa fa-book fa-2x text-success-m2 mr-1" />
                                    <span className="text-default-d3">Bootdey.com</span>
                                </div>
                            </div>
                        </div> */}
                    {/* .row */}
                    {/* <hr className="row brc-default-l1 mx-n1 mb-4" /> */}
                    <div className="row">
                        <div className="col-sm-6">
                            <div>
                                <span className="text-sm text-grey-m2 align-middle">Customer Name :&nbsp;</span>
                                <span className="text-600 text-110 text-blue align-middle"> { idata && idata.payoutcustomers && idata.payoutcustomers.customerName }</span>
                            </div>
                            <div>
                                <span className="text-sm text-grey-m2 align-middle">Customer Mobile :&nbsp;</span>
                                <span className="text-600 text-110 text-blue align-middle"> { idata && idata.payoutcustomers && idata.payoutcustomers.customerMobile }</span>
                            </div>
                            <div className="text-grey-m2">
                                <div className="my-1 text-600 text-110 text-blue">
                                    Beneficiary
                                </div>
                                <div className="my-1">
                                    Bank :  { idata && idata.bankName }
                                </div>
                                <div className="my-1">
                                    IFSC : { idata && idata.payoutbeneficiaries && idata.payoutbeneficiaries.ifscCode }
                                </div>
                                <div className="my-1">
                                    AC : { idata && idata.payoutbeneficiaries && idata.payoutbeneficiaries.accountNumber }
                                </div>
                                <div className="my-1">
                                    Name : { (idata && idata.payoutbeneficiaries && idata.payoutbeneficiaries.isVerified) ? idata.payoutbeneficiaries.beneBankName : idata && idata.payoutbeneficiaries && idata.payoutbeneficiaries.beneName }
                                </div>
                                <div className="my-1">
                                    Status : { idata && idata.payoutbeneficiaries && idata.payoutbeneficiaries.isVerified ? "Verified" : "Unverified" }
                                </div>
                            </div>
                        </div>
                        {/* /.col */}
                        <div className="text-95 col-sm-6 align-self-start d-sm-flex justify-content-end">
                            <hr className="d-sm-none" />
                            <div className="text-grey-m2">
                                {/* <div className="mt-1 mb-2 text-secondary-m1 text-600 text-125">
                                        Invoice
                                    </div> */}
                                <div className="my-2"><i className="fa fa-circle text-blue-m2 text-xs mr-1" /> <span className="text-600 text-90">Outlet : </span> {idata && idata.userdetails && idata.userdetails.outletName}</div>
                                <div className="my-2"><i className="fa fa-circle text-blue-m2 text-xs mr-1" /> <span className="text-600 text-90">Name : </span> {idata && idata.userdetails && idata.userdetails.firstName+' '+idata.userdetails.lastName }</div>
                                <div className="my-2"><i className="fa fa-circle text-blue-m2 text-xs mr-1" /> <span className="text-600 text-90">Contact : </span> {idata && idata.userdetails && idata.userdetails.mobile}</div>
                                <div className="my-2"><i className="fa fa-circle text-blue-m2 text-xs mr-1" /> <span className="text-600 text-90">Address : </span> {idata && idata.userdetails && idata.userdetails.communicationAddress}</div>
                            </div>
                        </div>
                        {/* /.col */}
                    </div>
                    <div className="mt-4">
                        <div className="row text-600 text-white bgc-default-tp1 py-25">
                            <div className="d-none d-sm-block col-3">Date&Time</div>
                            <div className="d-none d-sm-block col-3">Transaction ID</div>
                            <div className="d-none d-sm-block col-2">Amount</div>
                            <div className="d-none d-sm-block col-2">Charges</div>
                            <div className="d-none d-sm-block col-2">Status</div>
                        </div>
                        <div className="text-95 text-secondary-d3">
                            <div className="row mb-2 mb-sm-0 py-25">
                                <div className="d-none d-sm-block col-3">{getUTCToLocalDateTimeFormat(idata.createdAt)}</div>
                                <div className="d-none d-sm-block col-3">{idata.transactionId}</div>
                                <div className="d-none d-sm-block col-2">{idata.amount}</div>
                                <div className="d-none d-sm-block col-2">{idata && idata.ledgers && idata.ledgers.serviceCharge}</div>
                                <div className="d-none d-sm-block col-2">{idata.txtStatusDesc}</div>
                            </div>
                        </div>
                        <hr />
                        <div className="row border-b-2 brc-default-l2" />
                        <div className="row mt-3 mb-2">
                            <div className="col-12"><strong className="text-center">T&C / Disclaimer</strong></div>
                            <div className="col-12 text-grey-d2 text-95 mt-2 mt-lg-0">
                                <p className="m-0">1. Transaction charges are inclusive of GST.<br />
                                    {/* 2. This transaction receipt is only a provisional acknowledgment and is issued to customer mentioned herein for accepting mention payment for the above order and as per the detail provided by customer.<br />
                                    3. The customer is fully responsible for the accuracy of the detail as provided by him before a transaction is initiated. If any discrepancy found in given recipient mobile number "lost or invalid", account number and IFSC, Company will be not responsible for any kind of financial loss. */}
                                </p>
                            </div>
                        </div>
                        <hr />
                        <div>
                            <span className="text-secondary-d1 text-105">This is a computer generated receipt & does't require signature</span>
                            {isPrintButton && <a href="#/" onClick={() => print()} className="btn bg-white btn-light mx-1px text-95 float-right mt-3 mt-lg-0">
                                <i className="mr-1 fa fa-print text-primary-m1 text-120 w-2" /> Print
                            </a>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Invoice;