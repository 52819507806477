import React, { useEffect, useState, useRef } from 'react';
import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import { GET_PAYOUT_SERVICE_LIST_API } from './../../Utils/appConstants';
import { dmtDetailStatus } from './../../Utils/Common';
import { getRequest } from './../../Utils/AppApi';
import { useHistory } from "react-router-dom";
import PropagateLoader from "react-spinners/PropagateLoader";
import { DatePicker, Tooltip } from 'antd';
import { getUTCToLocalDateTimeFormat, toDayDate, queryDate } from './../../Utils/function';
// import { toast } from 'react-toastify';

function PayoutService(props) {
    const history = useHistory();
    const csvLink = useRef()
    const limit = 10;
    const dateFormat = 'DD-MM-YYYY';
    // const [searchUserId, setSearchUserId] = useState(null);
    // const [searchAccountNumber, setSearchAccountNumber] = useState(null);
    const [searchTransId, setSearchTransId] = useState(null);
    // const [searchCustomerId, setSearchCustomerId] = useState(null);
    // const [searchStatus, setSearchStatus] = useState(null);

    const [endDate, setEndDate] = useState(toDayDate());
    const [startDate, setStartDate] = useState(toDayDate());
    const [getData, setData] = useState([]);
    const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    // const [searchMode, setSearchMode] = useState(null);
    const [page, setPage] = useState(1);
    const [isLimit, setLimit] = useState(limit);
    const [isLoading, setLoading] = useState(false);
    // const [isEditModal, setEditModal] = useState(false);
    // const [isSelectedData, setSelectedData] = useState(null);
    // const [editStatus, setEditStatus] = useState(null);
    // const [editTid, setEditTid] = useState(null);

    const columns = [{
        name: 'Date Time',
        cell: row => getUTCToLocalDateTimeFormat(row.createdAt),
        width: "200px",
    }, {
        name: 'Trans ID',
        selector: row => row.client_ref_id,
        width: "180px",
    }, {
        name: 'Amount',
        selector: row => row.amount + ' ' + row.currency,
        width: "100px",
    }, {
        name: 'Partner ID',
        selector: row => <Tooltip placement="bottom" title={row.users?.username + ' / ' + row.users?.name}>{row.users?.username + ' / ' + row.users?.name}</Tooltip>,
        width: "200px",
    }, {
        name: 'Status',
        selector: row => dmtDetailStatus(row.dmttransactiondetails && row.dmttransactiondetails.tx_status),
        width: "180px",
    }, {
        name: 'TID',
        selector: row => <Tooltip placement="bottom" title={row.dmttransactiondetails?.tid}>{row.dmttransactiondetails?.tid}</Tooltip>,
        width: "200px",
    }, {
        name: 'Mode',
        selector: row => row.channel_desc,
    }, {
        name: 'Sub Trans ID',
        selector: row => row.dmttransactiondetails?.transactionId,
        width: "180px",
    }, {
        name: 'Customer ID',
        selector: row => <Tooltip placement="bottom" title={row.customer_id}>{row.customer_id}</Tooltip>,
        width: "130px",
    }, {
        name: 'Bank RRN',
        selector: row => <Tooltip placement="bottom" title={row.dmttransactiondetails?.bank_ref_num}>{row.dmttransactiondetails?.bank_ref_num}</Tooltip>,
        width: "160px",
    }, {
        name: 'IFSC Code',
        selector: row => row.ifsccode,
        width: "120px",
    }, {
        name: 'Account Number',
        selector: row => <Tooltip placement="bottom" title={row.accountNumber}>{row.accountNumber}</Tooltip>,
        width: "170px",
    }, {
        name: 'Reason',
        selector: row => <Tooltip placement="bottom" title={row.dmttransactiondetails?.reason}>{row.dmttransactiondetails?.reason}</Tooltip>,
        width: "180px",
    }];

    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: isLimit };

    const getDataReports = (data) => {
        if (data) {
            setLimit(data)
            params.limit = data;
        }
        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }

        // params.searchAccountNumber = searchAccountNumber ? searchAccountNumber : '';
        // params.searchCustomerId = searchCustomerId ? searchCustomerId : '';
        params.searchTransId = searchTransId ? searchTransId : '';
        // params.searchUserId = searchUserId ? searchUserId : '';
        // params.searchStatus = searchStatus ? searchStatus : '';
        // params.searchMode = searchMode ? searchMode : '';
        setLoading(true);
        const queryString = objectToQueryString(params);
        getRequest(`${GET_PAYOUT_SERVICE_LIST_API}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
            setLoading(false);
        }).catch(function (error) {
            console.log(error)
            setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const getCSVDataList = () => {
        let params = {}
        setLoading(true);
        params.exportsCsv = true;
        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }

        // params.searchAccountNumber = searchAccountNumber ? searchAccountNumber : '';
        // params.searchCustomerId = searchCustomerId ? searchCustomerId : '';
        params.searchTransId = searchTransId ? searchTransId : '';
        // params.searchUserId = searchUserId ? searchUserId : '';
        // params.searchStatus = searchStatus ? searchStatus : '';
        // params.searchMode = searchMode ? searchMode : '';

        const queryString = objectToQueryString(params);
        getRequest(`${GET_PAYOUT_SERVICE_LIST_API}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                let csvdataRecord = [];
                let csvdata = response.data;
                csvdata.map((e) => {
                    return csvdataRecord.push({
                        "Date Time": getUTCToLocalDateTimeFormat(e.createdAt),
                        "Trans ID": e.client_ref_id,
                        "Amount": e.amount,
                        "Partner ID": e.users?.username,
                        "Partner Name": e.users?.name,
                        "Status": e.dmttransactiondetails?.txstatus_desc,
                        "TID": e.dmttransactiondetails?.tid,
                        "Mode": e.channel_desc,
                        "Sub Trans ID": e.dmttransactiondetails?.transactionId,
                        "Customer ID": e.customer_id,
                        "Bank RRN": e.dmttransactiondetails?.bank_ref_num,
                        "IFSC Code": e.ifsccode,
                        "Account Number": e.accountNumber,
                        "Reason": e.dmttransactiondetails?.reason,
                    });
                });
                setCsvData(csvdataRecord);
                setTimeout(() => {
                    csvLink.current.link.click();
                }, 1000);
            } else {
                setCsvData([]);
            }
            setLoading(false);
        }).catch(function (error) {
            console.log(error)
            setLoading(false);
        })
    }

    const handlePageChange = (page, size) => {
        // console.log("-----", size)
        setPage(page);
    };

    const onChangeStartDate = (date, dateString) => {
        // console.log(date, dateString);
        setStartDate(date)
    };

    const onChangeEndDate = (date, dateString) => {
        // console.log(date, dateString);
        setEndDate(date)
    };

    // const onChangeEditStatus = (value) => {
    //     setEditStatus(value);
    // }

    const handleSearch = () => {
        getDataReports();
    }

    const handleClear = () => {
        setStartDate("");
        setEndDate("");
        // setSearchAccountNumber('');
        setSearchTransId('');
        // setSearchCustomerId('');
        // setSearchUserId('');
    }

    useEffect(() => {
        getDataReports();
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps
    // trans id | IFSC code | AC | AMT | BANK RRN | Status

    if (isLoading) {
        return <div className="content text-center" style={{ margin: "250px 0" }}>
            <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
        </div>
    }

    return (
        <div>
            <div className="content-wrapper pt-2">
                <div className="content-header my-0 py-0">
                    <div className="container-fluid">
                        <div className="row mb-0 align-items-center">
                            <div className="col-sm-6">
                                <h1 className='page-title text-blue font-merriweather'>Payout Service <span className='text-red'>Report</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className="row">
                            <div className='card col-md-12 py-3'>
                                <div className='card-body p-0'>
                                    <div className="row">
                                        <div className='col-md-12 date-search mb-0'>
                                            <div className='row'>
                                                <div className='col-md-3 col-lg-3 d-flex'>
                                                    <div className="form-group mr-1">
                                                        <label className='date-search-label mb-0 text-blue'>From</label>
                                                        <DatePicker className="form-control form-control-border" value={startDate} format={dateFormat} onChange={onChangeStartDate} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-red'>To</label>
                                                        <DatePicker className="form-control form-control-border" value={endDate} format={dateFormat} onChange={onChangeEndDate} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3 col-lg-3 d-flex'>
                                                    {/* <div className="form-group mr-3">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="User ID" onChange={(e) => setSearchUserId(e.target.value)} value={searchUserId} />
                                                    </div> */}
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="Trans ID" onChange={(e) => setSearchTransId(e.target.value)} value={searchTransId} />
                                                    </div>
                                                </div>
                                                {/* <div className='col-md-3 col-lg-3 d-flex'>
                                                    <div className="form-group mr-3">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="A/C Number" onChange={(e) => setSearchAccountNumber(e.target.value)} value={searchAccountNumber} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="Customer ID" onChange={(e) => setSearchCustomerId(e.target.value)} value={searchCustomerId} />
                                                    </div>
                                                </div> */}
                                                <div className='col-md-4 col-lg-4 d-flex'>
                                                    <div className='col-md-4 col-lg-4'>
                                                        <div className="form-group">
                                                            <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSearch()}>Search</button>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4 col-lg-4'>
                                                        <button type="submit" className="btn btn-default rounded-pill px-4" onClick={() => handleClear()}>Clear</button>
                                                    </div>
                                                    <div className='col-md-4 col-lg-4'>
                                                        <button type="button" className="btn btn-primary mr-3" onClick={() => getCSVDataList()} ><i className="fa fa-file-excel"></i> Export</button>
                                                        <CSVLink
                                                            data={getCsvData}
                                                            filename='payout-service'
                                                            className='hidden'
                                                            ref={csvLink}
                                                            target='_blank'
                                                        />
                                                    </div>
                                                    {/* <div className="form-group col-6">
                                                        <label className='date-search-label mb-0 text-red'>&nbsp;</label>
                                                        <Select options={dmtModeInArray} className={`normal-light w-100`} classNamePrefix="select" placeholder="-- Mode --" name='mode' onChange={(e) => onChangeTypeMode(e)} />
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label className='date-search-label mb-0 text-red'>&nbsp;</label>
                                                        <Select options={dmtDetailStatusInArray} className={`normal-light w-100`} classNamePrefix="select" placeholder="-- Status --" name='status' onChange={(e) => onChangeType(e)} />
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className='col-md-12 date-search mb-0'>
                                            <div className='row'>
                                                <div className='col-md-8'></div>
                                                <div className='col-md-1 col-lg-1'>
                                                    <div className="form-group">
                                                        <button type="submit" className="btn btn-primary rounded-pill pl-4 pr-4" onClick={() => handleSearch()}>Search</button>
                                                    </div>
                                                </div>
                                                <div className='col-md-1 col-lg-1'>
                                                    <button type="submit" className="btn btn-default rounded-pill px-4" onClick={() => handleClear()}>Clear</button>
                                                </div>
                                                <div className='col-md-2'>
                                                    <button type="button" className="btn btn-primary mr-3" onClick={() => getCSVDataList()} ><i className="fa fa-file-excel"></i> Export</button>
                                                    <CSVLink
                                                        data={getCsvData}
                                                        filename='payout-service'
                                                        className='hidden'
                                                        ref={csvLink}
                                                        target='_blank'
                                                    />
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="container-fluid pt-2 pb-5">
                                        <DataTable
                                            title={""}
                                            columns={columns}
                                            data={getData.docs}
                                            highlightOnHover
                                            pagination
                                            paginationServer
                                            progressPending={isLoading}
                                            paginationTotalRows={getTotalRecord}
                                            onChangeRowsPerPage={getDataReports}
                                            onChangePage={handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PayoutService;