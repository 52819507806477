import React, { useEffect, useState } from 'react';
// import { CSVLink } from 'react-csv';
import DataTable from 'react-data-table-component';
import { POST_SETTLEMENT_TO_BANK_STATUS, POST_SETTLEMENT_TO_BANK_PAYMENT_TRANSACTION, GET_SETTLEMENT_TO_BANK_ADMIN_HISTORY } from './../../../Utils/appConstants';
import { dmtStatusInArray, dmtModeInArray, channelStatus, INRFormat } from './../../../Utils/Common';
import { getRequest, postRequest } from './../../../Utils/AppApi';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import PropagateLoader from "react-spinners/PropagateLoader";
import { DatePicker, Tooltip, Space, Tag, Select, Popconfirm } from 'antd';
import { getUTCToLocalDateTimeFormat, toDayDate, queryDate } from '../../../Utils/function';

function SettlementToBank(props) {
    const history = useHistory();
    // const csvLink = useRef()
    const limit = 10;
    const dateFormat = 'DD-MM-YYYY';
    const [searchUserId, setSearchUserId] = useState(null);
    const [searchAccountNumber, setSearchAccountNumber] = useState(null);
    const [searchTransId, setSearchTransId] = useState(null);
    const [searchCustomerId, setSearchCustomerId] = useState(null);
    const [searchStatus, setSearchStatus] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [endDate, setEndDate] = useState(toDayDate());
    const [startDate, setStartDate] = useState(toDayDate());
    const [getData, setData] = useState([]);
    // const [getCsvData, setCsvData] = useState([]);
    const [getTotalRecord, setTotalRecord] = useState(0);
    const [searchMode, setSearchMode] = useState(null);
    const [page, setPage] = useState(1);
    const [isLimit, setLimit] = useState(limit);
    const [userId, setUserId] = useState(props.userId);
    // const [open, setOpen] = useState(false);

    // const [isChecked, setChecked] = useState([]);

    // const onClick = (event) => {
    //     let { checked, value } = event.target;
    //     if (checked) {
    //         setChecked([...isChecked, value]);
    //     } else {
    //         const updatedArray = isChecked.filter(item => item !== value);
    //         setChecked(updatedArray);
    //     }
    // }

    const columns = [{
        name: 'Date Time',
        cell: row => getUTCToLocalDateTimeFormat(row.createdAt),
        width: "200px",
    }, {
        name: 'Trans ID',
        selector: row => <Tooltip placement="bottom" title={row.transactionId}>{row.transactionId}</Tooltip>,
        width: "180px",
    }, {
        name: 'Amount',
        selector: row => INRFormat(row.amount),
        // width: "100px",
    }, {
        name: 'IFSC Code',
        selector: row => row.ifsccode,
        // width: "120px",
    }, {
        name: 'Account Number',
        selector: row => row.accountNumber,
        width: "170px",
    }, {
        name: 'Status',
        selector: row => row.tx_status_desc,
        // width: "120px",
    }, {
        name: 'Mode',
        selector: row => channelStatus(row.paymentMode),
        // width: "100px",
    }, {
        name: 'Partner ID',
        selector: row => <Tooltip placement="bottom" title={row.partnerId}>{row.partnerId}</Tooltip>,
        // width: "130px",
    }, {
        name: 'Partner Name',
        selector: row => <Tooltip placement="bottom" title={row.partnerName}>{row.partnerName}</Tooltip>,
        // width: "130px",
    }, {
        name: 'Send Money',
        selector: (record, key) => <>
            {!record.tx_status ?<Space key={key} size="middle" >
                <Popconfirm placement="left" title='Are you sure you want to Reject?' okText="Reject" cancelText="Cancel" onConfirm={() => handleStatus(record)} >
                    <Tag type="primary" color={"red"} className='pointer' >Reject</Tag>
                </Popconfirm>
                <Popconfirm placement="left" title='Are you sure you want to NEFT?' okText="NEFT" cancelText="Cancel" onConfirm={() => handleSubmit(record, 4)} >
                    <Tag type="primary" color={"green"} className='pointer' >NEFT</Tag>
                </Popconfirm>
                <Popconfirm placement="left" title='Are you sure you want to IMPS?' okText="IMPS" cancelText="Cancel" onConfirm={() => handleSubmit(record, 5)} >
                    <Tag type="primary" color={"blue"} className='pointer' >IMPS</Tag>
                </Popconfirm>
            </Space>: "No Action"}</>,

        // {/* <Popconfirm title="Are you sure?" okText="Approve" cancelText="Reject" onCancel={() => handleStatus(record)} ><Space key={key} size="middle">{!record.tx_status ? <><Tag color={"green"} className='pointer' onClick={() => handleSubmit(record, 4)} key={0}>Approve Payment(NEFT)</Tag><Tag color={"green"} className='pointer' onClick={() => handleSubmit(record, 5)} key={0}>Approve Payment(IMPS)</Tag></> : "No Action"}</Space></Popconfirm></>, */}
        width: "270px",
    }]

    // const handleOpenChange = (newOpen) => {
    //     // setOpen(newOpen); // Update visibility based on user interaction
    // };

    const handleSubmit = (data, channel) => {
        // console.log("data ------------------------------", data.transactionId)
        // setOpen(false);
        if (data) {
            try {
                setLoading(true)
                const payload = {
                    transactionId: data._id,
                    channel: channel,
                }
                postRequest(POST_SETTLEMENT_TO_BANK_PAYMENT_TRANSACTION, payload, history).then((response) => {
                    if (response.success === true) {
                        getDataReports();
                        toast.success(response.message);
                    } else {
                        toast.error(response.message);
                    }
                    setLoading(false);
                }).catch(function (error) {
                    // console.log(error)
                    setLoading(false);
                });
            } catch (error) {
                console.log("error ------------------------------", error)
            }
        }
    }

    const handleStatus = (data) => {
        if (data) {
            try {
                setLoading(true)
                const payload = {
                    transactionId: data.transactionId,
                    status: 7,
                }
                postRequest(POST_SETTLEMENT_TO_BANK_STATUS, payload, history).then((response) => {
                    if (response.success === true) {
                        getDataReports();
                        toast.success(response.message);
                    } else {
                        toast.error(response.message);
                    }
                    setLoading(false);
                }).catch(function (error) {
                    // console.log(error)
                    setLoading(false);
                });
            } catch (error) {
                console.log("error ------------------------------", error)
            }
        }
    }


    const objectToQueryString = (obj) => {
        var str = [];
        for (var p in obj)
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        return str.join("&");
    }

    const params = { page: page, limit: isLimit };

    const getDataReports = (data) => {
        if (data) {
            setLimit(data)
            params.limit = data;
        }
        if (startDate && endDate) {
            params.startDate = startDate ? queryDate(startDate) : "";
            params.endDate = endDate ? queryDate(endDate) : "";
        }

        if (props.userId) {
            params.userId = userId ? userId : props.userId;
        }

        params.searchAccountNumber = searchAccountNumber ? searchAccountNumber : '';
        params.searchCustomerId = searchCustomerId ? searchCustomerId : '';
        params.searchTransId = searchTransId ? searchTransId : '';
        params.searchUserId = searchUserId ? searchUserId : '';
        params.searchStatus = searchStatus ? searchStatus : '';
        params.searchMode = searchMode ? searchMode : '';

        const queryString = objectToQueryString(params);
        // setLoading(true)
        getRequest(`${GET_SETTLEMENT_TO_BANK_ADMIN_HISTORY}?${queryString}`, history).then((response) => {
            if (response.success === true) {
                setData(response.data);
                setTotalRecord(response.data.totalDocs);
                setPage(response.data.page);
            } else {
                setData([]);
                setTotalRecord(0);
                setPage(1);
            }
            // setLoading(false)
        }).catch(function (error) {
            console.log(error)
            // setLoading(false);
        })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const handlePageChange = (page, size) => {
        console.log("-----", size)
        setPage(page);
    };

    const onChangeStartDate = (date, dateString) => {
        // console.log("date----------", dateString);
        setStartDate(date)
    };

    const onChangeEndDate = (date, dateString) => {
        // console.log(date, dateString);
        setEndDate(date)
    };

    const handleSearch = () => {
        getDataReports();
    }

    // const transactionInquiry = (data) => {
    //     console.log("data -------------", data.transactionId)
    //     setLoading(true)
    //     const payload = { transactionId: data.transactionId }
    //     postRequest(`${POST_PAYOUT_GET_TRANSACTION_INQUIRY}`, payload, history).then((response) => {
    //         if (response.success === true) {
    //             toast.success(response.message);
    //         } else {
    //             toast.error(response.message);
    //         }
    //         getDataReports();
    //         setLoading(false);
    //     }).catch(function (error) {
    //         // console.log(error)
    //         setLoading(false);
    //     })
    // }

    const onChangeType = (value) => {
        setSearchStatus(value);
    }

    const onChangeTypeMode = (value) => {
        setSearchMode(value);
    }

    const handleClear = () => {
        setStartDate('');
        setEndDate('');
        setSearchAccountNumber('');
        setSearchTransId('');
        setSearchCustomerId('');
        setSearchUserId('');
    }

    useEffect(() => {
        if (searchAccountNumber || searchTransId || searchCustomerId || searchUserId || searchStatus || searchMode) {
            handleSearch();
        }
    }, [searchAccountNumber, searchTransId, searchCustomerId, searchUserId, searchStatus, searchMode]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.userId) {
            setUserId(props.userId)
        }
        getDataReports();
    }, [page]) // eslint-disable-line react-hooks/exhaustive-deps
    // trans id | IFSC code | AC | AMT | BANK RRN | Status

    if (isLoading) {
        return <div className="content text-center" style={{ margin: "250px 0" }}>
            <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
        </div>
    }

    return (
        <div>
            <div className="content-wrapper pt-2">
                <div className="content-header my-0 py-0">
                    <div className="container-fluid">
                        <div className="row mb-0 align-items-center">
                            <div className="col-sm-6">
                                <h1 className='page-title text-blue font-merriweather'>Settlement <span className='text-red'>To Bank Report</span></h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container-fluid pt-2 pb-5">
                        <div className="row">
                            <div className='card col-md-12 py-3'>
                                <div className='card-body p-0'>
                                    <div className="row">
                                        <div className='col-md-12 date-search mb-0'>
                                            <div className='row'>
                                                <div className='col-md-3 col-lg-3 d-flex'>
                                                    <div className="form-group mr-3">
                                                        <label className='date-search-label mb-0 text-blue'>From</label>
                                                        <DatePicker className="form-control form-control-border" value={startDate} format={dateFormat} onChange={onChangeStartDate} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-red'>To</label>
                                                        <DatePicker className="form-control form-control-border" value={endDate} format={dateFormat} onChange={onChangeEndDate} />
                                                    </div>
                                                </div>

                                                <div className='col-md-3 col-lg-3 d-flex'>
                                                    <div className="form-group mr-3">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="User ID" onChange={(e) => setSearchUserId(e.target.value)} value={searchUserId} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="Trans ID" onChange={(e) => setSearchTransId(e.target.value)} value={searchTransId} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3 col-lg-3 d-flex'>
                                                    <div className="form-group mr-3">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="A/C Number" onChange={(e) => setSearchAccountNumber(e.target.value)} value={searchAccountNumber} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className='date-search-label mb-0 text-red'></label>
                                                        <input type="text" className="form-control form-control-border" placeholder="Customer ID" onChange={(e) => setSearchCustomerId(e.target.value)} value={searchCustomerId} />
                                                    </div>
                                                </div>
                                                <div className='col-md-3 col-lg-3 d-flex'>
                                                    <div className="form-group col-6">
                                                        <label className='date-search-label mb-0 text-red'>&nbsp;</label>
                                                        <Select options={dmtModeInArray} className={`normal-light w-100`} classNamePrefix="select" placeholder="-- Mode --" name='mode' onChange={(e) => onChangeTypeMode(e)} />
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <label className='date-search-label mb-0 text-red'>&nbsp;</label>
                                                        <Select options={dmtStatusInArray} className={`normal-light w-100`} classNamePrefix="select" placeholder="-- Status --" name='status' onChange={(e) => onChangeType(e)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container-fluid pt-2 pb-5">
                                        <DataTable
                                            title={""}
                                            columns={columns}
                                            data={getData.docs}
                                            defaultSortFieldID={1}
                                            highlightOnHover
                                            pagination
                                            paginationServer
                                            actions={<><button type="submit" className="btn btn-primary rounded-pill px-3" onClick={() => handleSearch()}>Search</button><button type="submit" className="btn btn-default rounded-pill px-4" onClick={() => handleClear()}>Clear</button></>}
                                            paginationTotalRows={getTotalRecord}
                                            onChangeRowsPerPage={getDataReports}
                                            onChangePage={handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SettlementToBank